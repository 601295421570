import { AnyAction, combineReducers, Reducer } from 'redux';

import applyBarcodeInProgress from './applyBarcodeInProgress';
import creditCardPaymentInformation from './creditCardPaymentInformation';
import deliveryAddress from './deliveryAddress';
import deliveryCoordinates from './deliveryCoordinates';
import deliveryEstimateInProgress from './deliveryEstimateInProgress';
import deliveryNotes from './deliveryNotes';
import deliveryProvider from './deliveryProvider';
import deliverySurcharges from './deliverySurcharges';
import deliveryTime from './deliveryTime';
import giftCard from './giftCard';
import locationId from './locationId';
import menu from './menu';
import minimumOrderInformation from './minimumOrderInformation';
import notes from './notes';
import offerId from './offerId';
import offers from './offers';
import openPurchase from './openPurchase';
import openPurchaseLowestBalance from './openPurchaseLowestBalance';
import orderingProviderSurchargeInstructions from './orderingProviderSurchargeInstructions';
import payment from './payment';
import paymentGatewayConfig from './paymentGatewayConfig';
import pickupTime from './pickupTime';
import promoCode from './promoCode';
import purchaser from './purchaser';
import remainingTotal from './remainingTotal';
import saleDetails from './saleDetails';
import saleInProgress from './saleInProgress';
import saleType from './saleType';
import selectedPaymentMethods from './selectedPaymentMethods';
import stagedPurchases from './stagedPurchases';
import table from './table';
import timeEstimateReturned from './timeEstimateReturned';
import uuid from './uuid';
import validationInProgress from './validationInProgress';
import stockBalances from './stockBalances';
import etag from './etag';
import cachedMenu from './cachedMenu';
import bestEstId from './bestEstId';
import maxOrderValueRules from './maxOrderValueRules';
import desiredDeliveryTime from './desiredDeliveryTime';

const rootReducer: Reducer<Order, AnyAction> = combineReducers({
  applyBarcodeInProgress,
  creditCardPaymentInformation,
  deliveryAddress,
  deliveryCoordinates,
  deliveryEstimateInProgress,
  deliveryNotes,
  deliveryProvider,
  deliverySurcharges,
  deliveryTime,
  giftCard,
  locationId,
  menu,
  minimumOrderInformation,
  notes,
  offerId,
  offers,
  openPurchase,
  openPurchaseLowestBalance,
  orderingProviderSurchargeInstructions,
  payment,
  paymentGatewayConfig,
  pickupTime,
  promoCode,
  purchaser,
  remainingTotal,
  saleDetails,
  saleInProgress,
  saleType,
  selectedPaymentMethods,
  stagedPurchases,
  table,
  timeEstimateReturned,
  uuid,
  validationInProgress,
  stockBalances,
  etag,
  cachedMenu,
  bestEstId,
  maxOrderValueRules,
  desiredDeliveryTime,
});

export default rootReducer;
