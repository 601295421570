import React, { useRef, useState, useEffect } from 'react';
import lodash from 'lodash';
import moment from 'moment';
import { RiArrowRightFill, RiSearchLine, RiPencilFill, RiAlertFill } from 'react-icons/ri';
import { withTranslation } from 'react-i18next';
import GoogleMapReact from 'google-map-react';

import {
  OrderingSelectors,
  OrderingOperations,
  OrderingConstants,
  determineLocationSessions,
} from 'polygon-ordering';

import { logEvent } from '../utils/analytics';
import combineStyles from '../utils/combineStyles';
import { TEXT_PROPERTIES, CONTAINER_PROPERTIES } from '../utils/theme';
// import { convertCoordsToShortKeys } from '../utils/maps';
import getLocalValue from '../utils/getLocalValue';

import { MOBILE_DISPLAY_WIDTH_THRESHOLD, IN_DEVELOPMENT } from '../constants';
import EVENTS from '../constants/events';

// import { updateCurrentUserLocationAction } from '../thunks/updateCurrentUserLocation';
import applyBufferedDeliveryEstimateAction from '../actions/applyBufferedDeliveryEstimate';
import performDeliveryEstimateAction from '../actions/performDeliveryEstimate';
import confirmLocationEstimateAction from '../actions/confirmLocationEstimate';
import adjustSaleTypeAction from '../actions/adjustSaleType';

import { setDesiredDeliveryAddress } from '../slices/desiredDeliveryAddress';
import { cacheAddress } from '../slices/addressCache';

import getThemeLookup from '../selectors/getThemeLookup';
// import getUserLocation from '../selectors/getUserLocation';
import getDeviceTypeMobile from '../selectors/getDeviceTypeMobile';
import getExtremelyShortScreen from '../selectors/getExtremelyShortScreen';
import getDisplayableSaleTypes from '../selectors/getDisplayableSaleTypes';

import Modal from '../components/Modal';
import StandardButton from '../components/StandardButton';
import Text from '../components/Text';
import TimeSelector from '../components/TimeSelector';
import MapMarker from '../components/MapMarker';
import TouchableOpacity from '../components/TouchableOpacity';
import DriverNotes from '../components/DriverNotes';
import LocationListElement from '../components/LocationListElement';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import { convertCoordsToShortKeys } from '../utils/maps';
import getUserLocation from '../selectors/getUserLocation';

const {
  getBufferDeliveryAddressString,
  getBufferDeliveryTime,
  getDeliveryReadyToApply,
  getDeliveryRequiresLocationSelection,
  getBufferDeliveryCoordinates,
  getLocationDeliveryEstimates,
  getLocationsForDisplay,
  getBrands,
  getOrderingWindowPadding,
  getDesiredDeliveryTime,
  // getBufferWarnings,
} = OrderingSelectors;
const { clearBuffer, setDesiredDeliveryTime } = OrderingOperations;

const { SALE_TYPES, ASAP_TIME, ORDERING_PROVIDERS } = OrderingConstants;

export const DELIVERY_DETAILS_MODAL_ID = 'DELIVERY_DETAILS_MODAL_ID';

const DeliveryDetailsModal: React.FC<any> = props => {
  const addressFieldRef = useRef<any>();
  const [state, setState] = useState<{ matches: any[]; selectedLocationId: any }>({
    matches: [],
    selectedLocationId: null,
  });
  const [geocoder, setGeocoder] = useState<any>();
  const dispatch = useAppDispatch();
  const { t } = props;
  const p = useAppSelector(getThemeLookup);
  const brands = useAppSelector(getBrands);
  const userLocation = useAppSelector(getUserLocation);
  const bufferDeliveryAddressString = useAppSelector(getBufferDeliveryAddressString);
  const bufferDeliveryCoordinates = useAppSelector(getBufferDeliveryCoordinates);
  const bufferDeliveryTime = useAppSelector(getBufferDeliveryTime);
  const deliveryEstimateFailed = useAppSelector(state => state.deliveryEstimateFailed);
  const deliveryEstimateInProgress = useAppSelector(state => state.deliveryEstimateInProgress);
  const keyOrderPropertyUpdateInProgress = useAppSelector(
    state => state.keyOrderPropertyUpdateInProgress,
  );
  const deliveryReadyToApply = useAppSelector(getDeliveryReadyToApply);
  const deliveryRequiresLocationSelection = useAppSelector(getDeliveryRequiresLocationSelection);
  const desiredDeliveryAddress = useAppSelector(state => state.desiredDeliveryAddress);
  const desiredDeliveryAddressNotFound = useAppSelector(
    state => state.desiredDeliveryAddressNotFound,
  );
  const desiredDeliveryTime = useAppSelector(getDesiredDeliveryTime);
  const enableDeliveryNotes = useAppSelector(state => state.config.enableDeliveryNotes);
  const deviceTypeMobile = useAppSelector(getDeviceTypeMobile);
  const displayableSaleTypes = useAppSelector(getDisplayableSaleTypes);
  const extremelyShortScreen = useAppSelector(getExtremelyShortScreen);
  const googleMapsApiKey = useAppSelector(state => state.config.googleMapsApiKey);
  const hideTimesForSaleTypes = useAppSelector(state => state.config.hideTimesForSaleTypes);
  const locationDeliveryEstimates = useAppSelector(getLocationDeliveryEstimates);
  const locations = useAppSelector(getLocationsForDisplay);
  const orderingWindowPadding = useAppSelector(getOrderingWindowPadding);
  const thresholdOrderingProviders = useAppSelector(
    state => state.config.thresholdOrderingProviders,
  );
  const geocoderCountryRestriction = useAppSelector(
    state => state.config.geocoderCountryRestriction,
  );

  // TODO: this needs to be swapped out with deliveryTimeOpen and deliveryTimeClose
  const fallbackOpeningHours = useAppSelector(state => state.config.fallbackOpeningHours);
  const fallbackClosingHours = useAppSelector(state => state.config.fallbackClosingHours);
  const deliveryTimeOpen = useAppSelector(state => state.config.deliveryTimeOpen);
  const deliveryTimeClose = useAppSelector(state => state.config.deliveryTimeClose);

  // ! Temporary: Remove later
  useEffect(() => {
    console.log(
      'Old delivery fallback hours (store closing hours): \n',
      fallbackOpeningHours,
      '->',
      fallbackClosingHours,
    );
    console.log('New delivery fallback hours: \n', deliveryTimeOpen, '->', deliveryTimeClose);
  }, [fallbackOpeningHours, fallbackClosingHours, deliveryTimeOpen, deliveryTimeClose]);

  const addressCache = useAppSelector(state => state.addressCache);

  const geocode = (value: any) => {
    const cached = lodash.get(addressCache, value);

    if (cached?.results) {
      handleResults(cached.results, 'OK', value);
    } else if (geocoder) {
      geocoder.geocode(
        {
          address: value,
          componentRestrictions: {
            country: geocoderCountryRestriction,
          },
        },
        (results: any, status: any) => {
          dispatch(cacheAddress({ results: results || [], address: value }));

          handleResults(results, status, value);
        },
      );
    } else {
      console.error('geocoder mising');
    }
  };
  const throttledGeocode = lodash.throttle(geocode, 1000);

  useEffect(() => {
    dispatch(setDesiredDeliveryAddress(bufferDeliveryAddressString as string));
  }, [bufferDeliveryAddressString]);

  const initGeocoder = ({ maps }: any) => {
    setGeocoder(new maps.Geocoder());
  };

  const handleDesiredAddressChange = (event: any) => {
    logEvent(EVENTS.CHANGE_DELIVERY_ADDRESS);

    const value = event?.target?.value;

    dispatch(setDesiredDeliveryAddress(value));

    if (!value || !value.trim()) {
      clearMatches();
      return;
    }

    if (!geocoder || value.length < 5) {
      return;
    }

    throttledGeocode(value);
  };

  const handleResults = (results: any, status: any, raw: any) => {
    if (status === 'OK') {
      setState({
        ...state,
        matches: results
          .map((match: any) => match.formatted_address.replace(/, Australia$/, ''))
          .slice(0, 4),
      });

      // save raw string to dict in local storage? no, redux
    } else {
      clearMatches();
    }
  };

  const clearMatches = () => setState({ ...state, matches: [] });

  const selectAddress = (value: any) => {
    logEvent(EVENTS.SELECT_GEOCODED_DELIVERY_ADDRESS);

    clearMatches();

    dispatch(setDesiredDeliveryAddress(value || null));

    if (addressFieldRef?.current) {
      addressFieldRef.current.blur();
    }
  };

  const { matches, selectedLocationId: selectedLocationIdState } = state;

  let effectiveDeliveryAddress =
    desiredDeliveryAddress == null ? bufferDeliveryAddressString : desiredDeliveryAddress;

  effectiveDeliveryAddress = effectiveDeliveryAddress || '';

  const effectiveDeliveryTime = desiredDeliveryTime || bufferDeliveryTime;

  const addressTooShort = effectiveDeliveryAddress.length < 10;

  const inProgress = deliveryEstimateInProgress || keyOrderPropertyUpdateInProgress; // can I just use one?

  const disableMap = IN_DEVELOPMENT && getLocalValue('disableMap');

  const estimateLocations = locationDeliveryEstimates
    .map((estimate: any) => {
      let location = locations[estimate.locationId];

      if (!location) {
        return null;
      }

      return {
        ...location,
        ...determineLocationSessions(
          location,
          orderingWindowPadding,
          SALE_TYPES.DELIVERY.valueOf(),
        ),
        bestDeliveryTime: estimate.bestDeliveryTime,
      };
    })
    .filter((location: any) => location != null);

  const selectedLocationId = selectedLocationIdState || estimateLocations[0]?.id;

  const checked = deliveryReadyToApply || deliveryRequiresLocationSelection;

  const hideMap = !checked || !bufferDeliveryCoordinates || extremelyShortScreen;

  const confirm = () => dispatch(confirmLocationEstimateAction({ locationId: selectedLocationId }));

  const hideTimes = (hideTimesForSaleTypes || []).includes(SALE_TYPES.DELIVERY);

  const thesholdPresent = (thresholdOrderingProviders || []).some(
    // eslint-disable-next-line eqeqeq
    (provider: any) => provider == ORDERING_PROVIDERS.REDCAT,
  );

  return (
    <Modal
      title={t(`saleType.${SALE_TYPES.DELIVERY}`)}
      desktopMaxWidth={450}
      desktopMinWidth={MOBILE_DISPLAY_WIDTH_THRESHOLD}
      containerStyle={styles.modalContainer}
      mobileContainerStyle={styles.modalMobileContainer}
      tallestPossible
      expandToMaxWidth
      forceFloatingTitle
      locked={inProgress}
    >
      <div
        style={combineStyles(
          styles.mapContainer,
          hideMap && { height: 0 }, // hidden via height because we need it to geocode?
        )}
      >
        <GoogleMapReact
          bootstrapURLKeys={
            disableMap
              ? undefined
              : {
                  key: googleMapsApiKey as string,
                }
          }
          onGoogleApiLoaded={initGeocoder}
          yesIWantToUseGoogleMapApiInternals
          center={
            bufferDeliveryCoordinates
              ? {
                  lat: bufferDeliveryCoordinates.latitude,
                  lng: bufferDeliveryCoordinates.longitude,
                }
              : undefined
          }
          defaultZoom={17}
          //@ts-ignore
          defaultCenter={convertCoordsToShortKeys(userLocation)}
          options={() => ({
            fullscreenControl: false,
            clickableIcons: false, // disable clicking on PT icons
            gestureHandling: 'none',
            keyboardShortcuts: false,
          })}
          onZoomAnimationEnd={() => logEvent(EVENTS.CHANGE_MAP_ZOOM)}
          onDragEnd={() => logEvent(EVENTS.PAN_MAP)}
        >
          {Boolean(bufferDeliveryCoordinates) && (
            <MapMarker
              lat={bufferDeliveryCoordinates?.latitude}
              lng={bufferDeliveryCoordinates?.longitude}
            />
          )}
        </GoogleMapReact>
      </div>
      <div style={styles.mainContainer}>
        <div style={styles.upperContainer}>
          {checked ? (
            <>
              <Text themeKey="sectionTitle" style={styles.detailTitle} block>
                {t('title.deliveryAddress')}
              </Text>

              <Text themeKey="deliveryEstimateDetail" block>
                {effectiveDeliveryAddress}
              </Text>

              {!hideTimes && !deliveryRequiresLocationSelection && (
                <>
                  <Text themeKey="sectionTitle" style={styles.detailTitle} block>
                    {t('title.deliveryTime.estimated')}
                  </Text>
                  <Text themeKey="deliveryEstimateDetail" block>
                    {moment(effectiveDeliveryTime).format('LT')}
                  </Text>
                </>
              )}

              <StandardButton
                label={t('button.editDeliveryEstimate')}
                themeKey="editDeliveryEstimateButton"
                RightIconComponent={RiPencilFill}
                onClick={() => {
                  logEvent(EVENTS.EDIT_DELIVERY_DETAILS);
                  dispatch(setDesiredDeliveryTime(effectiveDeliveryTime));
                  dispatch(setDesiredDeliveryAddress(effectiveDeliveryAddress!));
                  dispatch(clearBuffer({}));
                  setState({ ...state, selectedLocationId: null });
                }}
                containerStyle={styles.editButton}
                disabled={inProgress}
              />
            </>
          ) : (
            <>
              <Text themeKey="sectionTitle" style={styles.title} block>
                {t('title.deliveryAddress')}*{' '}
                {desiredDeliveryAddressNotFound && (
                  <RiAlertFill
                    style={p('desiredDeliveryAddressNotFoundIcon', ['color', 'fontSize'])}
                  />
                )}
              </Text>

              <input
                style={combineStyles(
                  styles.inputField,
                  p('defaultText', TEXT_PROPERTIES),
                  p('input', CONTAINER_PROPERTIES),
                )}
                type="text"
                value={effectiveDeliveryAddress}
                placeholder={t('placeholder.locationSearch')}
                onChange={handleDesiredAddressChange}
                onBlur={() => setTimeout(() => clearMatches(), 300)}
              />

              {Boolean(matches.length) && (
                <div style={styles.matchesAnchor}>
                  <div style={styles.matchesContainer}>
                    {matches.map(match => (
                      <TouchableOpacity
                        key={match}
                        onClick={() => {
                          selectAddress(match);
                        }}
                        style={styles.matchContainer}
                        hoverStyle={styles.hoveredMatch}
                        ariaLabel={match}
                      >
                        <Text style={styles.match}>{match}</Text>
                      </TouchableOpacity>
                    ))}
                  </div>
                </div>
              )}

              {!hideTimes && (
                <>
                  <Text themeKey="sectionTitle" style={styles.title} block>
                    {t('title.deliveryTime.desired')}
                  </Text>

                  <TimeSelector
                    themeKey="deliveryTimeSelect"
                    earliestTime={deliveryTimeOpen!}
                    latestTime={deliveryTimeClose!}
                    timePadding={10}
                    value={effectiveDeliveryTime}
                    setValue={(value: string | null) => {
                      const time = value || ASAP_TIME;
                      logEvent(EVENTS.CHANGE_DELIVERY_TIME, { label: time });
                      dispatch(setDesiredDeliveryTime(time));
                    }}
                  />
                </>
              )}
            </>
          )}

          {enableDeliveryNotes && !deliveryRequiresLocationSelection && (
            <>
              <Text
                themeKey="sectionTitle"
                style={combineStyles(styles.title, checked && styles.notesSection)}
                block
              >
                {t('title.deliveryNotes')}
              </Text>

              <DriverNotes />
            </>
          )}
        </div>

        {deliveryRequiresLocationSelection && (
          <div style={styles.locationsSection}>
            <div style={styles.locationList}>
              {estimateLocations.map((location: any) => (
                <LocationListElement
                  p={p}
                  t={t}
                  deviceTypeMobile={deviceTypeMobile}
                  key={location.id}
                  location={location}
                  selected={location.id === selectedLocationId}
                  selectLocation={(id: any) => setState({ ...state, selectedLocationId: id })}
                  proceed={confirm}
                  hideDistance={true}
                  brands={brands}
                  alwaysShowBrands
                  hideAddress
                  disabled={inProgress}
                  estimatedDeliveryTime={
                    hideTimes
                      ? undefined
                      : `${t('eta')}: ${moment(location.bestDeliveryTime).format('LT')}`
                  }
                  hideLeft
                />
              ))}
            </div>
          </div>
        )}

        {(deliveryEstimateFailed || deliveryRequiresLocationSelection) &&
          displayableSaleTypes.includes(SALE_TYPES.PICKUP.valueOf()) && (
            <div style={styles.fallbackToPickupContainer}>
              <Text themeKey="fallbackToPickupMessage" value={t('fallbackToPickupMessage')} />

              <StandardButton
                themeKey="fallbackToPickupButton"
                label={t('button.fallbackToPickup')}
                RightIconComponent={RiArrowRightFill}
                disabled={inProgress}
                containerStyle={styles.fallbackToPickupButton}
                onClick={() =>
                  dispatch(
                    adjustSaleTypeAction({
                      saleType: SALE_TYPES.PICKUP,
                      locationId: selectedLocationId,
                    }),
                  )
                }
              />
            </div>
          )}
      </div>

      {thesholdPresent && (
        <Text
          themeKey="deliveryTimeMayVaryMessage"
          style={combineStyles(styles.title, styles.deliveryTimeMayVaryMessage)}
          block
          lines={2}
        >
          {t('deliveryTimeMayVaryMessage')}
        </Text>
      )}

      <StandardButton
        label={
          checked
            ? deliveryRequiresLocationSelection && estimateLocations.length > 1
              ? t('button.estimateDelivery.choose')
              : t('button.estimateDelivery.confirm')
            : inProgress
            ? t('button.estimateDelivery.checking')
            : t('button.estimateDelivery.check')
        }
        disabled={addressTooShort || inProgress}
        onClick={
          deliveryReadyToApply
            ? () => {
                logEvent(EVENTS.CONFIRM_DELIVERY_DETAILS);
                dispatch(applyBufferedDeliveryEstimateAction());
              }
            : deliveryRequiresLocationSelection
            ? confirm
            : () => {
                logEvent(EVENTS.CHECK_DELIVERY_DETAILS);

                dispatch(
                  performDeliveryEstimateAction({
                    deliveryAddress: effectiveDeliveryAddress,
                    desiredDeliveryTime: effectiveDeliveryTime,
                  }),
                );
              }
        }
        themeKey="modalProceedButton"
        RightIconComponent={checked ? RiArrowRightFill : undefined}
        LeftIconComponent={!checked && !inProgress ? RiSearchLine : undefined}
        containerStyle={styles.button}
        showSpinner={inProgress}
      />
    </Modal>
  );
};

const styles: Styles = {
  modalContainer: {
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },
  modalMobileContainer: {
    // https://stackoverflow.com/a/30523342/8706204
    WebkitMaskImage: '-webkit-radial-gradient(white, black)',
  },

  title: {
    marginTop: 20,
    marginBottom: 15,
  },

  detailTitle: {
    marginTop: 15,
    marginBottom: 7,
  },

  inputField: {
    width: '100%',
    minHeight: 40,
    paddingLeft: 5,
  },

  mapContainer: {
    height: '25%',
    overflow: 'hidden',
  },

  mainContainer: {
    flex: 1,
    overflowY: 'auto',

    paddingTop: 10,
    paddingBottom: 30,
  },

  upperContainer: {
    paddingLeft: 30,
    paddingRight: 30,
  },

  matchesAnchor: {
    width: '100%',
    height: 0,
    position: 'relative',
  },

  matchesContainer: {
    position: 'absolute',
    top: -2,
    backgroundColor: '#F9F9F9',
    width: '100%',
    border: '1px solid rgba(0, 0, 0, 0.2)',
  },

  matchContainer: {
    padding: 10,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  hoveredMatch: {
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
  },
  match: {
    display: 'block',
    fontSize: 13,
  },

  button: {
    borderRightWidth: 0,
    borderLeftWidth: 0,
    borderBottomWidth: 0,
  },

  notesSection: {
    marginTop: 50,
  },

  deliveryTimeMayVaryMessage: {
    alignSelf: 'center',
    width: '70%',
    marginBottom: 25,
    textAlign: 'center',
  },

  editButton: {
    marginTop: 25,
  },

  locationsSection: {
    marginTop: 30,
  },

  locationList: {
    // paddingBottom: 50,
    borderTop: '1px solid rgba(0,0,0,0.1)',
  },

  fallbackToPickupContainer: {
    // backgroundColor: 'pink',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',

    // minHeight: 100,
    padding: 25,
  },

  fallbackToPickupButton: {
    // marginTop: 5,
  },
};

export default withTranslation()(DeliveryDetailsModal);
