import React, { useState } from 'react';
import lodash from 'lodash';
import setLocalValue from '../utils/setLocalValue';
import combineStyles from '../utils/combineStyles';
import { enqueueErrorSnackbar, enqueueSuccessSnackbar } from '../utils/snackbar';
import { logEvent } from '../utils/analytics';
import { IN_DEVELOPMENT } from '../constants';
import EVENTS from '../constants/events';
import redcatApiFetch from '../api';
import getThemeLookup from '../selectors/getThemeLookup';
import getRegisterProfileFields from '../selectors/getRegisterProfileFields';
import getRegisterProfileSchema from '../selectors/getRegisterProfileSchema';
import getRegisterProfileInitialValues from '../selectors/getRegisterProfileInitialValues';
import getConfig from '../selectors/getConfig';
import syncMemberAction from '../actions/syncMember';
import { setCurrentModal } from '../thunks/setCurrentModal';
import StandardButton from '../components/StandardButton';
import ReCAPTCHA from 'react-google-recaptcha';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import { useTranslation } from 'react-i18next';
import getDeviceTypeMobile from '../selectors/getDeviceTypeMobile';
import { fetchOffers } from '../thunks/fetchOffers';
import { registerUser } from '../utils/analytics';
import { OrderingSelectors } from 'polygon-ordering';
import { Formik, Form } from 'formik';
import RegistrationFields from '../components/RegistrationFields';
import { SIGN_IN_MODAL_ID } from './SignInForm';
import { useLocation, useHistory } from 'react-router-dom';

const { getOrderTotals, getStagedPurchases } = OrderingSelectors;

export const REGISTER_MODAL_ID = 'REGISTER_MODAL_ID';

const Link: React.FC<{
  url: string;
  openInNewTab?: boolean;
  onClick?: () => void;
  children?: React.ReactChildren;
}> = ({ url, openInNewTab, onClick, children }) => {
  const p = useAppSelector(getThemeLookup);
  return (
    <a
      href={url}
      style={combineStyles(styles.link, p('signInLink', ['fontFamily', 'fontWeight']))}
      target={openInNewTab ? '_blank' : undefined}
      onClick={onClick}
    >
      {children}
    </a>
  );
};

const Register = ({ modal = false }: { modal?: boolean }) => {
  const { t } = useTranslation();
  const registrationFields = useAppSelector(getRegisterProfileFields);
  const registratonFieldSchema = useAppSelector(getRegisterProfileSchema);
  let registrationFieldInitialValues = useAppSelector(getRegisterProfileInitialValues);
  const config = useAppSelector(getConfig);
  const dispatch = useAppDispatch();
  const deviceTypeMobile = useAppSelector(getDeviceTypeMobile);
  const { enableReCaptcha, reCaptchaSiteKey } = config;
  const orderTotals = useAppSelector(getOrderTotals);
  const stagedPurchases = useAppSelector(getStagedPurchases);
  const showRedeemedCoupons = useAppSelector(state => state.config.showRedeemedCoupons);
  const showExpiredCoupons = useAppSelector(state => state.config.showExpiredCoupons);

  const history = useHistory();

  const pathname = useLocation();

  const getRegistrationCode = () => {
    let registrationCode = null;

    if (pathname.state) {
      registrationCode = pathname.state.registrationCode;
    }

    return registrationCode;
  };

  const registrationCodeField = registrationFields?.all.find(({ id }) => id === 'RegistrationCode');
  const registrationCodeMode = registrationCodeField?.mode;
  const registrationCodeInputType = registrationCodeField?.inputType;
  const registrationCode = getRegistrationCode();

  //recaptcha settings
  const [reCaptchaToken, setReCaptchaToken] = useState<string | null>(null);

  const onReCaptchaChange = (reCaptchaToken: string | null) => {
    setReCaptchaToken(reCaptchaToken);
  };

  const onReCaptchaError = () => {
    clearReCaptchaToken();

    enqueueErrorSnackbar(t('problemWithReCaptchaMessage'));
  };

  const clearReCaptchaToken = () => setReCaptchaToken(null);

  const confirmPasswordMode = registrationFields?.visible.find(
    ({ id }: { id: string }) => id === 'ConfirmPassword',
  )?.mode;

  const getInitialValues = () => {
    // Need to add the registration code to the initial values if the registration code exists and if the registration code field is not disabled
    if (registrationCode && registrationCodeMode) {
      registrationFieldInitialValues = {
        ...registrationFieldInitialValues,
        [registrationCodeInputType === 'hidden' ? 'hidden' : 'visible']: {
          ...registrationFieldInitialValues.visible,
          RegistrationCode: registrationCode,
        },
      };
    }
    return registrationFieldInitialValues.visible;
  };

  const submit = (
    values: FormikFields,
    { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void },
  ) => {
    let sentValues = { ...values };
    // When ConfirmPassword mode is set to 'optional' it won't validate but a value still needs to be sent to the endpoint
    if (confirmPasswordMode === 'optional') sentValues.ConfirmPassword = values.Password;

    //If the user has a registration code but the registration code input type is 'hidden' then add the value here
    if (registrationCode && registrationCodeInputType === 'hidden') {
      sentValues = { ...sentValues, RegistrationCode: getRegistrationCode() };
    }

    const MOBILE_REG_NZ = /(^02|^\+642|^03|^\+643)([\d]{7,10})$/;
    const regNzMobile = new RegExp(MOBILE_REG_NZ);
    const { Mobile } = sentValues;
    if (Mobile) {
      if (regNzMobile.test(Mobile)) {
        if (String(Mobile).startsWith('0')) {
          sentValues = { ...sentValues, Mobile: '+64' + String(Mobile).slice(1) };
        }
      }
    }

    if (values['Terms' as keyof FormikFields] === true)
      delete sentValues['Terms' as keyof FormikFields];
    //register api call
    redcatApiFetch({
      path: 'api/v1/register',
      method: 'POST',
      body: { ...sentValues, 'g-recaptcha-response': reCaptchaToken },
    })
      .then((result: any) => {
        registerUser(
          'native',
          result.success,
          orderTotals,
          stagedPurchases.map(purchaseItem => {
            return {
              ...purchaseItem.item,
              totalPrice: purchaseItem.moneyPrice,
            };
          }),
          result.data,
        );

        dispatch(fetchOffers({ showRedeemedCoupons, showExpiredCoupons }));
        //register successfully
        setSubmitting(false);
        logEvent(EVENTS.MEMBER_REGISTER); // log event
        clearReCaptchaToken();

        if (IN_DEVELOPMENT && result.token) {
          setLocalValue('memberAuthToken', result.token);
        }

        dispatch(syncMemberAction());
        if (modal) {
          dispatch(setCurrentModal(null));
        } else {
        }
        history.push({
          pathname: '/registration_verify',
          state: {
            isRegistration: true,
          },
        });
      })
      .catch(e => {
        setSubmitting(false);
        clearReCaptchaToken();
        const data = lodash.get(e, 'details.json', {});
        if (data.error_code === 3) {
          enqueueSuccessSnackbar('Verification PIN required');
        } else {
          logEvent(EVENTS.MEMBER_REGISTER_FAILED);
          enqueueErrorSnackbar(e);
        }
      });
  };

  return (
    <div style={styles.innerContainer}>
      <Formik
        validationSchema={registratonFieldSchema!.visible}
        initialValues={getInitialValues()}
        onSubmit={submit}
        enableReinitialize={true}
        validateOnMount
      >
        {({ handleSubmit, isSubmitting, isValid, dirty }) => {
          return (
            <Form onSubmit={handleSubmit} style={styles.registrationForm}>
              <>
                {registrationFields!.visible.map((field: RegisterFields, index: any) => (
                  <RegistrationFields key={index} field={field} />
                ))}
                {enableReCaptcha && !reCaptchaToken && (
                  <div style={styles.captchaContainer}>
                    <ReCAPTCHA
                      sitekey={String(reCaptchaSiteKey)}
                      onChange={onReCaptchaChange}
                      onErrored={onReCaptchaError}
                      size={deviceTypeMobile ? 'compact' : 'normal'}
                    />
                  </div>
                )}

                <StandardButton
                  label={t('button.register')}
                  containerStyle={styles.button}
                  disabled={Boolean(isSubmitting || !isValid || !dirty)}
                  themeKey="signInSubmitButton"
                  onClick={handleSubmit}
                />

                <Link url="#" onClick={() => dispatch(setCurrentModal(SIGN_IN_MODAL_ID))}>
                  {t('button.signIn')}
                </Link>
              </>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default Register;

const styles: Styles = {
  passwordStrengthBar: {
    marginTop: '1rem',
    fontSize: '0.7rem',
  },
  modalDesktopContainer: {
    padding: '50px 70px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    textTransform: 'uppercase',
  },
  innerContainer: {
    minHeight: 'min-content',
    marginTop: '1rem',
  },
  buttonContainer: {
    display: 'flex',
    paddingTop: '1rem',
    paddingBottom: '1rem',
    justifyContent: 'space-between',
  },
  registrationForm: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'strech',
    justifyContent: 'space-between',
  },
  button: {
    color: 'white',
    textTransform: 'uppercase',
    // padding: '15px',
    height: 40,
    marginTop: '1.5rem',
    cursor: 'pointer',
  },
  link: {
    marginTop: '1rem',
    marginLeft: 'auto',
    marginRight: 'auto',
    fontSize: '0.8rem',
  },
};
